// TODO: add Typescript Typings
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { default as _get } from "lodash/get";

import ContentImage, {
  ContentImageWrapper,
} from "../components/2-molecules/ContentImage/ContentImage";

const ContentfulHeroImage = ({
  content,
  disableMarginBottom = false,
  disableMarginTop = false,
}) => {
  const image = {
    caption: _get(content, "caption", undefined),
    src: _get(content, "image.gatsbyImageData", undefined),
    alt: _get(content, "title", undefined),
  };

  return typeof image.src !== "undefined" ? (
    <ContentImageWrapper
      disableMarginBottom={disableMarginBottom}
      disableMarginTop={disableMarginTop}
    >
      <ContentImage caption={image.caption}>
        <GatsbyImage image={image.src} alt={image.alt} />
      </ContentImage>
    </ContentImageWrapper>
  ) : null;
};

export default ContentfulHeroImage;

export const query = graphql`
  fragment FragmentHeroImageStage on ContentfulComponentImage {
    id
    caption
    title
    image {
      gatsbyImageData(placeholder: BLURRED, width: 1120, aspectRatio: 2)
    }
  }
  fragment FragmentHeroImageAspectRatio16_9 on ContentfulComponentImage {
    id
    caption
    title
    image {
      #gatsbyImageData(placeholder: BLURRED, width: 1110, aspectRatio: 1.77)
      gatsbyImageData(placeholder: BLURRED, width: 730, aspectRatio: 1.77)
    }
  }
  fragment FragmentHeroImage on ContentfulComponentImage {
    id
    caption
    title
    image {
      # gatsbyImageData(placeholder: BLURRED, width: 1110)
      gatsbyImageData(placeholder: BLURRED, width: 730)
    }
  }
`;
