import React from "react";

import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import DynamicTag from "../../0-electrons/DynamicTag";
import * as css from "./TypographyIntro.module.scss";

export interface TypographyIntroProps {
  children: React.ReactNode;
  className?: string;
  tag?: keyof JSX.IntrinsicElements;
}

export const TypographyIntro: React.FC<TypographyIntroProps> = ({
  children,
  className,
  tag = "p",
}: TypographyIntroProps) => {
  return (
    <DynamicTag
      tag={tag}
      className={classNames(getClassName(css, "intro"), {
        [className as string]:
          typeof className === "string" && className.length > 0,
      })}
    >
      {children}
    </DynamicTag>
  );
};

export default TypographyIntro;
