import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo, GatsbySeoProps } from "gatsby-plugin-next-seo";

import { default as _get } from "lodash/get";

import { getUrl } from "../helper/uri";

export interface ContentfulSeoProps {
  composeSeo?: GatsbySeoProps;
  title?: string;
}

const ContentfulSeo: React.FC<ContentfulSeoProps> = ({
  composeSeo,
  title,
}: ContentfulSeoProps) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          seo {
            titleTemplate
          }
        }
      }
    }
  `);
  const siteMetadataSeo = _get(data, "site.siteMetadata.seo");

  const canonicalSlug = _get(composeSeo, "canonicalUrl.slug", undefined);
  const jsonLd = _get(composeSeo, "jsonLd.internal.content", null);

  // meta tags
  const composeSeoMetaTags = _get(composeSeo, "keywords", null);
  const metaTags =
    Array.isArray(composeSeoMetaTags) && composeSeoMetaTags.length > 0
      ? [
          {
            name: "keywords",
            content: composeSeoMetaTags.join(", "),
          },
        ]
      : undefined;

  return (
    <>
      <GatsbySeo
        title={_get(composeSeo, "seoTitle", null) || title}
        titleTemplate={_get(siteMetadataSeo, "titleTemplate", undefined)}
        description={_get(composeSeo, "description", undefined)}
        metaTags={metaTags}
        noindex={_get(composeSeo, "noindex", undefined)}
        nofollow={_get(composeSeo, "nofollow", undefined)}
        canonical={
          typeof canonicalSlug === "string" ? getUrl(canonicalSlug) : undefined
        }
      />
      {typeof jsonLd === "string" ? (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            ${jsonLd}`,
          }}
        />
      ) : null}
    </>
  );
};

export default ContentfulSeo;

export const query = graphql`
  fragment ComposeSeo on ContentfulComposeSeo {
    seoTitle
    description
    keywords
    noindex
    nofollow
    jsonLd {
      internal {
        content
      }
    }
    canonicalUrl {
      slug
    }
  }
  fragment BlogPostComposeSeo on ContentfulBlogPost {
    composeSeo {
      ...ComposeSeo
    }
  }
  fragment CategoryPageComposeSeo on ContentfulCategoryPage {
    composeSeo {
      ...ComposeSeo
    }
  }
`;
