import React from "react";

import { default as _get } from "lodash/get";
import { Link, graphql } from "gatsby";
import { BreadcrumbJsonLd } from "gatsby-plugin-next-seo";

import Breadcrumbs from "../components/2-molecules/Breadcrumbs/Breadcrumbs";

import { getUrl, removeSiteUrl } from "../helper/uri";

// Typings for Data from Contentful/GraphQL
export interface ContentfulBreadcrumbsDataComposeSeo {
  seoTitle: string;
}
export interface ContentfulBreadcrumbsDataCategoryPage {
  composeSeo?: ContentfulBreadcrumbsDataComposeSeo;
  slug: string;
  title: string;
  titleShort?: string;
}

export interface ContentfulBreadcrumbsDataBlogPost {
  categories: Array<ContentfulBreadcrumbsDataCategoryPage>;
  slug: string;
  title: string;
  titleShort?: string;
}

// Breadcrumb component data parsing typings
export interface BreadcrumbsItemProps {
  item: string;
  name: string;
  nameShort?: string;
  position?: number;
}
export type BreadcrumbsItems = Array<BreadcrumbsItemProps>;
export interface BreadcrumbsItemsProps {
  items: BreadcrumbsItems;
}

/**
 * ContentfulBreadcrumbs Connector Component
 */
const ContentfulBreadcrumbs: React.FC<BreadcrumbsItemsProps> = ({
  items = [],
}: BreadcrumbsItemsProps) => {
  const itemsWithHome = [
    {
      name: "Home",
      item: getUrl(),
    },
    ...items,
  ];

  const itemsWithPosition = itemsWithHome.map((item, index) => {
    return {
      ...item,
      key: `breadcrumb-${index}`,
      position: index + 1,
    };
  });

  return (
    <>
      <BreadcrumbJsonLd itemListElements={itemsWithPosition} />
      <Breadcrumbs>
        {itemsWithPosition.map(item => {
          return (
            <Link to={removeSiteUrl(item.item)} key={item.key}>
              {typeof item.nameShort === "string" && item.nameShort.length > 0
                ? item.nameShort
                : item.name}
            </Link>
          );
        })}
      </Breadcrumbs>
    </>
  );
};

export default ContentfulBreadcrumbs;

/**
 * Helper Functions
 */

const parseBreadcrumbCategoryPageItem = (
  data: ContentfulBreadcrumbsDataCategoryPage
): BreadcrumbsItemProps => {
  return {
    item: getUrl(_get(data, "slug", "")),
    name: _get(data, "composeSeo.seoTitle", null) || _get(data, "title", null),
    nameShort: _get(data, "titleShort", undefined),
  };
};

export const parseBreadcrumbsDataBlogPost = (
  data: ContentfulBreadcrumbsDataBlogPost
): BreadcrumbsItems => {
  return [
    // category Page
    parseBreadcrumbCategoryPageItem(_get(data, "categories[0]")),
    // blog post
    {
      name:
        _get(data, "composeSeo.seoTitle", null) || _get(data, "title", null),
      item: getUrl(data.slug),
    },
  ];
};

export const parseBreadcrumbsDataCategoryPage = (
  data: ContentfulBreadcrumbsDataCategoryPage
): BreadcrumbsItems => {
  return [parseBreadcrumbCategoryPageItem(data)];
};

/**
 * GraphQL Fragments
 */
export const query = graphql`
  fragment FragmentBreadcrumbsBlogPost on ContentfulBlogPost {
    title
    slug
    composeSeo {
      seoTitle
    }
    categories {
      title
      titleShort
      slug
    }
  }

  fragment FragmentBreadcrumbsCategoryPage on ContentfulCategoryPage {
    title
    titleShort
    slug
    composeSeo {
      seoTitle
    }
  }
`;
