// TODO: upgrade to TSX
import React from "react";

import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import * as css from "./TypographySubline.module.scss";

export const tags = ["h2", "h3", "h4"];
export const textTransforms = ["none", "uppercase"];
export const fontWeights = ["medium", "bold"];

export interface TypographySublineProps {
  children?: React.ReactNode;
  className?: string;
  tag?: typeof tags[number];
  textTransform?: typeof textTransforms[number];
  fontWeight?: typeof fontWeights[number];
}

export const TypographySubline: React.FC<TypographySublineProps> = ({
  children,
  className,
  tag = "h2",
  textTransform = "none",
  fontWeight = "bold",
}) => {
  const Subline = `${tag}`;

  return (
    <Subline
      className={classNames(
        getClassName(css, "subline"),
        getClassName(css, `subline--text-${textTransform}`),
        getClassName(css, `subline--text-${fontWeight}`),
        {
          [className as string]:
            typeof className === "string" && className.length > 0,
        }
      )}
    >
      {children}
    </Subline>
  );
};

export default TypographySubline;
