import React from "react";
import { default as _get } from "lodash/get";
import isAfter from "date-fns/isAfter";

import { graphql } from "gatsby";

import { Visible } from "../components/0-electrons/Visible";
import TypographyHeadline from "../components/1-atoms/TypographyHeadline/TypographyHeadline";

import ContentfulArticleHeader from "../contentful/ContentfulArticleHeader";
import ContentfulHeroImage from "../contentful/ContentfulIHeroImage";
import ContentfulBlogTeaser from "../contentful/ContentfulBlogTeaser";
import ContentfulSeo from "../contentful/ContentfulSeo";
import ContentfulStageVideo from "../contentful/ContentfulStageVideo";
import ContentfulBreadcrumbs, {
  parseBreadcrumbsDataCategoryPage,
} from "../contentful/ContentfulBreadcrumbs";
import ContentfulSliderCards from "../contentful/ContentfulSliderCards";

import { mapNodesToPosts, parseSections } from "../contentful/helper";

const chronologically = (a: any, b: any) =>
  new Date(a.startingDate) > new Date(b.startingDate) ? 1 : -1;

const createCounters = (counters: Array<any> = []) => {
  const today = new Date();
  return counters.sort(chronologically).map((current, i, arr) => {
    const previous = arr[i - 1];
    const endDate = new Date(current.startingDate);
    const isLive = isAfter(today, endDate);
    const startDate = previous?.startingDate
      ? new Date(previous.startingDate)
      : new Date("2022-08-22");
    return {
      ...current,
      startDate,
      endDate,
      isLive,
    };
  });
};

const isEmpty = function (obj: any) {
  return (
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

const isNil = (x: any) => x == null;

export const query = graphql`
  query ($id: String!) {
    # Category Page
    contentfulCategoryPage(id: { eq: $id }) {
      id
      heroVideoPosition
      # heroVideo {
      #   ...FragmentContentfulStageVideo
      # }
      heroImage {
        ...FragmentHeroImageAspectRatio16_9
      }
      heroImagePosition
      title
      subline {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      composeSeo {
        seoTitle
      }
      slug
      publishDate(formatString: "DD.MM.YYYY")
      intro {
        intro
      }
      ...CategoryPageComposeSeo
      sections {
        ...FragmentContentfulImage
        ...FragmentContentfulImageText
        ...FragmentContentfulLocationFinder
        ...FragmentContentfulRichText
        ...FragmentContentfulSliderCards
        ...FragmentContentfulTeaserBig
        ...FragmentContentfulTeaserGroup
        ...FragmentContentfulVideo
        #...FragmentContentfulStageVideo
      }
      sectionsFooter {
        ...FragmentContentfulImage
        ...FragmentContentfulImageText
        ...FragmentContentfulLocationFinder
        ...FragmentContentfulRichText
        ...FragmentContentfulSliderCards
        ...FragmentContentfulTeaserBig
        ...FragmentContentfulTeaserGroup
        ...FragmentContentfulVideo
        # ...FragmentContentfulStageVideo
      }
      relatedEntriesMode
      relatedEntries {
        ...TeaserBlogPost
        ...TeaserCategoryPage
      }
    }

    # Breadcrumb
    breadcrumbs: contentfulCategoryPage(id: { eq: $id }) {
      ...FragmentBreadcrumbsCategoryPage
    }

    # Posts of Category
    allContentfulBlogPost(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...TeaserBlogPost
        }
      }
    }

    counters: allContentfulComponentCounter {
      nodes {
        id
        bannerText
        buttonLabel
        buttonLink
        progressText
        startingDate
        teaserReference {
          ... on ContentfulComposeCard {
            image {
              id
              title
              gatsbyImageData(aspectRatio: 1.33, width: 700)
            }
            kicker
            title
            copy
            buttonLabel
            buttonTextLink
            buttonContentLink {
              slug
            }
          }
          ... on ContentfulComponentVideoContent {
            ...FragmentContentfulVideo
          }
        }
      }
    }
  }
`;

export const CategoryTemplate = props => {
  const categoryPage = _get(props, "data.contentfulCategoryPage", null);
  const { title, subline, intro, slug, seoTitle } =
    parseCategoryPageData(categoryPage);

  const heroImagePosition = _get(categoryPage, "heroImagePosition", undefined);
  // const heroVideoPosition = _get(categoryPage, "heroVideoPosition", undefined);
  const heroVideo = _get(categoryPage, "heroVideo", undefined);

  /**
   * Combine/replace blogPosts and related posts for teaser boxes
   * relatedEntriesMode can be 'default' (append relatedEntries to
   * automatically selected posts) or 'replace' (only use the relatedEntries
   * insted of the automically selected posts)
   */
  const relatedEntriesMode = _get(
    props,
    "data.contentfulCategoryPage.relatedEntriesMode",
    false
  );
  const blogPosts = mapNodesToPosts(
    _get(props, "data.allContentfulBlogPost.edges", [])
  );

  const hasBlogPosts = Array.isArray(blogPosts) && blogPosts.length > 0;
  const relatedPosts = _get(
    props,
    "data.contentfulCategoryPage.relatedEntries",
    []
  );
  const hasRelatedPosts =
    Array.isArray(relatedPosts) && relatedPosts.length > 0;

  const teaser =
    relatedEntriesMode === "replace" && hasRelatedPosts
      ? relatedPosts
      : [
          ...(hasBlogPosts ? blogPosts : []),
          ...(hasRelatedPosts ? relatedPosts : []),
        ];

  const isSliderVisible = /neurodermitis\/uebersicht/.test(categoryPage?.slug);
  const counters = createCounters(props.data?.counters?.nodes);
  const counterSlides = counters
    .filter(
      c =>
        !isNil(c.teaserReference) &&
        !isEmpty(c.teaserReference) &&
        !c.teaserReference.video
    )
    .map(c => c.teaserReference);

  return (
    <article>
      <ContentfulSeo
        title={title}
        composeSeo={_get(categoryPage, "composeSeo", {})}
      />

      <ContentfulBreadcrumbs
        items={parseBreadcrumbsDataCategoryPage(
          _get(props, "data.breadcrumbs", [])
        )}
      />

      {typeof heroVideo !== "undefined" ? (
        <ContentfulStageVideo content={heroVideo} />
      ) : null}

      <ContentfulArticleHeader
        headline={title}
        subline={subline}
        intro={intro}
      />
      {heroImagePosition !== "hide" ? (
        <ContentfulHeroImage
          content={_get(categoryPage, "heroImage", undefined)}
        />
      ) : null}

      {parseSections(
        _get(categoryPage, "sections", []),
        _get(props, "pageContext.locations", [])
      )}

      {relatedEntriesMode === "hide" ? null : (
        <ContentfulBlogTeaser posts={teaser} />
      )}

      <Visible when={isSliderVisible}>
        <div style={{ textAlign: "center" }}>
          <TypographyHeadline size="h2" disableSpacing>
            Fakten zum Thema Neurodermatitis
          </TypographyHeadline>
        </div>
        <ContentfulSliderCards
          content={{ theme: "white", items: counterSlides }}
        />
      </Visible>

      {parseSections(
        _get(categoryPage, "sectionsFooter", []),
        _get(props, "pageContext.locations", [])
      )}
    </article>
  );
};

function parseCategoryPageData(data) {
  const title = _get(data, "title");
  const subline = _get(
    data,
    "subline.childMarkdownRemark.rawMarkdownBody",
    null
  );
  const intro = _get(data, "intro.intro", null);
  const slug = _get(data, "slug", null);
  const seoTitle = _get(data, "composeSeo.seoTitle", null);

  return { title, subline, intro, slug, seoTitle };
}

export default CategoryTemplate;
