import React from "react";

import { default as _get } from "lodash/get";

import { getClassName } from "../../0-electrons/css";

import TypographyHeadline from "../../1-atoms/TypographyHeadline/TypographyHeadline";
import TypographyIntro from "../../1-atoms/TypographyIntro/TypographyIntro";
import TypographySubline from "../../1-atoms/TypographySubline/TypographySubline";
import MetaInfo, {
  MetaInfoProps,
} from "../../2-molecules/MetaInformation/MetaInfo";

import { stringNotEmpty } from "../../../helper/strings";

import * as css from "./ArticleHeader.module.scss";

export interface ArticleHeaderProps {
  headline: string;
  subline?: string;
  intro?: string;
  meta?: MetaInfoProps;
}

const ArticleHeader: React.FC<ArticleHeaderProps> = ({
  headline,
  subline,
  intro,
  meta,
}: ArticleHeaderProps) => {
  const metaChildren = _get(meta, "children", null);
  const date = _get(meta, "date", undefined);
  const duration = _get(meta, "duration", undefined);
  const hasMeta =
    React.isValidElement(metaChildren) ||
    typeof date === "string" ||
    typeof duration === "string";

  return (
    <header className={getClassName(css, "ArticleHeader")}>
      {stringNotEmpty(headline) ? (
        <TypographyHeadline size="h1" disableSpacing>
          {headline}
        </TypographyHeadline>
      ) : null}

      {stringNotEmpty(subline) ? (
        <TypographySubline textTransform="uppercase">
          {subline}
        </TypographySubline>
      ) : null}

      {hasMeta ? (
        <MetaInfo date={date} duration={duration}>
          {metaChildren}
        </MetaInfo>
      ) : null}

      {stringNotEmpty(intro) ? (
        <TypographyIntro>{intro}</TypographyIntro>
      ) : null}
    </header>
  );
};

export default ArticleHeader;
