import React from "react";

import { default as _isEmpty } from "lodash/isEmpty";
import { default as _has } from "lodash/has";

import { Link } from "gatsby";

import { getRootUri } from "../helper/uri";

import ArticleHeader, {
  ArticleHeaderProps,
} from "../components/3-organisms/ArticleHeader/ArticleHeader";

const ContentfulArticleHeader: React.FC<ArticleHeaderProps> = ({
  headline,
  subline,
  intro,
  meta,
}: ArticleHeaderProps) => {
  const hasMeta = !_isEmpty(meta);

  return (
    <ArticleHeader
      headline={headline}
      subline={subline}
      intro={intro}
      meta={{
        children:
          hasMeta && _has(meta, "children")
            ? categoryLinks(meta!.children as CategoryLinksProps)
            : undefined,
        date: _has(meta, "date") ? meta!.date : undefined,
        duration: _has(meta, "duration") ? meta!.duration : undefined,
      }}
    />
  );
};

interface CategoryProps {
  slug: string;
  title: string;
  titleShort?: string;
}

type ElementsOrNull = Array<React.ReactElement> | null;
type CategoryLinksProps = CategoryProps[] | null;

function hasCategories(categories: CategoryLinksProps): boolean {
  return Array.isArray(categories) && categories.length > 0;
}

function categoryLinks(categories: CategoryLinksProps): ElementsOrNull {
  return hasCategories(categories)
    ? (categories || []).map(category => (
        <Link to={getRootUri(category.slug)} key={category.slug}>
          {category.titleShort || category.title}
        </Link>
      ))
    : null;
}

export default ContentfulArticleHeader;
