import React from "react";

import { getClassName } from "../../0-electrons/css";
import Clone from "../../0-electrons/Clone/Clone";
import Icon from "../../0-electrons/Icon/Icon";

import * as css from "./MetaInfo.module.scss";

export interface MetaInfoProps {
  children?: React.ReactNode | Array<React.ReactElement>;
  date?: string;
  duration?: number;
}

export const MetaInfo: React.FC<MetaInfoProps> = ({
  children,
  date,
  duration,
}: MetaInfoProps) => {
  const childrenCount = React.Children.count(children);
  const childrenNodeCount = React.Children.toArray(children).length;

  return (
    <div className={getClassName(css, "MetaInfo")}>
      {typeof date === "string" && date.length > 0 ? (
        <div className={getClassName(css, "MetaInfo__date")}>{date}</div>
      ) : null}

      {typeof duration === "number" ? (
        <div className={getClassName(css, "MetaInfo__timeContainer")}>
          <Icon
            type="clock"
            color="grey-50"
            className={getClassName(css, "MetaInfo__timeContainer__icon")}
          />
          Lesedauer: {duration} Min.
        </div>
      ) : null}

      {childrenNodeCount > 0 ? (
        <div className={getClassName(css, "MetaInfo__categories")}>
          {React.Children.map(children, (child, index) => {
            return React.isValidElement(child) ? (
              <>
                <Clone
                  node={child}
                  className={getClassName(css, "MetaInfo__categoryLink")}
                />
                {index + 1 < childrenCount ? ", " : null}
              </>
            ) : null;
          })}
        </div>
      ) : null}
    </div>
  );
};

export default MetaInfo;
