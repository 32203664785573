import React from "react";
import classNames from "classnames";

import * as css from "./Breadcrumbs.module.scss";

import { Clone } from "../../0-electrons/Clone/Clone";
import { getClassName } from "../../0-electrons/css";
import { Icon } from "../../0-electrons/Icon/Icon";

export interface BreadcrumbsProps {
  children?: React.ReactNode;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  children,
}: BreadcrumbsProps) => {
  const childrenCount = React.Children.count(children);
  const isBrowser = typeof window !== undefined;
  const isMobile = () => {
    if (!isBrowser) {
      return;
    } else {
      window.matchMedia("(max-width: 768px)");
    }
  };
  return (
    <div className={getClassName(css, "Breadcrumbs")}>
      <div className={getClassName(css, "Breadcrumbs__container")}>
        {React.Children.map(children, (child, index) => {
          return React.isValidElement(child) ? (
            <>
              {index + 1 === childrenCount && isMobile ? (
                <Icon
                  type="directionLeft"
                  color="primary"
                  fluid={true}
                  className={getClassName(css, "Breadcrumbs__icon--current")}
                />
              ) : null}
              <Clone
                key={index}
                node={child}
                className={classNames(
                  getClassName(css, "Breadcrumbs__link"),
                  index + 1 === childrenCount
                    ? getClassName(css, "Breadcrumbs__link--current")
                    : null
                )}
              />
              {index + 1 < childrenCount ? (
                <Icon
                  type="directionRight"
                  color="grey-50"
                  fluid={true}
                  className={getClassName(css, "Breadcrumbs__icon")}
                />
              ) : null}
            </>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default Breadcrumbs;
