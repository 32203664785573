import React from "react";

const Visible = ({
  when = true,
  children,
}: {
  when?: boolean;
  children: React.ReactNode;
}): JSX.Element => <>{when ? children : null}</>;

const Hidden = ({
  when = true,
  children,
}: {
  when?: boolean;
  children: React.ReactNode;
}): JSX.Element => <>{when ? null : children}</>;

export { Hidden, Visible };
